<template>
    <div class="report">
        <div class="match">
            <div class="label">比赛信息:</div>
            <el-select v-if="showSelect" filterable v-model="match_id" placeholder="请选择比赛信息" style="width:350px">
                <el-option
                v-for="(item, index) in matchList"
                :key="'match'+index"
                :label="item.text"
                :value="item.match_id">
                </el-option>
            </el-select>
            <p>{{ matchTxt }}</p>
        </div>
        <div class="form">
            <reportItem
                v-for="(item, index) in reportCount" :key="'item'+index"
                :data="report_list[index]"
                :ref="'report'+index"
            ></reportItem>
            <div class="add" @click="add">新增情报</div>
            <el-input
                style="width: 760px;margin-top: 20px;"
                type="textarea"
                :rows="3"
                maxlength="30"
                placeholder="请输入比赛推荐方向"
                v-model="direction"
            >
            </el-input>
            <div class="channel">
                <el-input
                style="width: 760px"
                type="textarea"
                :rows="2"
                placeholder="请输入串关专家id"
                v-model="cs_eid">
                </el-input>
            </div>
            <div class="channel">
                <el-input
                style="width: 760px"
                type="textarea"
                :rows="2"
                placeholder="请输入总进球专家id"
                v-model="tl_eid">
                </el-input>
            </div>
            <div class="redio">
                <div class="label">是否公开：</div>
                <div class="radioBox">
                    <el-radio v-model="is_public" :label="1">是</el-radio>
                    <el-radio v-model="is_public" :label="0">否</el-radio>
                </div>
            </div>
            <div class="oper">
                <span>取消</span>
                <span @click="submit">提交</span>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../../service/api';
import reportItem from "../../components/reportItem.vue";
import utils from "../../utils"
export default {
    name: 'reprot',
    components: { reportItem },
    data () {
        return {
            matchList: [],
            match_id: parseInt(utils.getQueryVariable('match_id')) || '',
            matchTxt: '',
            direction: '',
            is_public: 0,
            report_list: [{}],
            reportCount: 1,
            cs_eid: '',
            tl_eid: '',
            showSelect: true
        }
    },
    created () {
        if (utils.getQueryVariable('match_id')) {
            this.getReportDetails();
            this.showSelect = false
        } else {
            this.initMatchList();
            this.showSelect = true
        }
    },
    methods: {
        async getReportDetails () {
            const res = await api.reportDetails({
                match_id: this.match_id
            });
            if (res.code === 0) {
                this.matchTxt = res.data.match_info
                this.report_list = res.data.report_list;
                this.reportCount = res.data.report_list.length;
                this.direction = res.data.direction;
                this.is_public = res.data.is_public;
                this.cs_eid = res.data.cs_eid;
                this.tl_eid = res.data.tl_eid;
            }
        },
        async submit () {
            if (!this.match_id) {
                alert('请选择比赛');
                return
            }
            const param = {
                match_id: this.match_id
            };
            const reportList = [];
            for (let i = 0; i < this.report_list.length; i++) {
                const node = this.$refs['report' + i][0];
                if (node.title && node.content) {
                    const obj = {};
                    obj.title = node.title;
                    obj.content = node.content;
                    obj.belong_team = node.belong_team;
                    obj.tag = node.tag;
                    obj.is_used = node.is_used;
                    obj.match_id = this.match_id;
                    if (node.path) {
                        obj.img = node.path;
                    }
                    if (node.id) {
                        obj.id = node.id;
                    }
                    reportList.push(obj)
                } else {
                    alert('请完整填写情报内容');
                    return
                }
            }
            param.report_list = reportList;
            if (this.direction) {
                param.direction = this.direction
            }
            if (this.cs_eid) {
                param.cs_eid = this.cs_eid
            }
            if (this.tl_eid) {
                param.tl_eid = this.tl_eid
            }
            param.is_public = this.is_public
            const res = await api.reportPublish(param);
            if (res.code === 0) {
                this.$message({
                    type: 'sucess',
                    message: '操作成功！'
                })
                window.parent.location.href = 'https://dpi.zjqvv.com/op-admin/report/match'
            } else {
                alert(res.msg)
            }
        },
        add () {
            this.reportCount++;
            this.report_list.push({})
        },
        async initMatchList () {
            const res = await api.reportMatchList();
            if (res.code === 0) {
                this.matchList = res.data
            } else {
                alert(res.msg)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.report{
    width: 800px;
    padding: 20px;
    text-align: left;
    margin: 0 auto;
    .match{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .label{
            margin-right: 10px;
        }
    }
    .add{
        width: 200px;
        height: 30px;
        background-color: rgb(33, 80, 221);
        text-align: center;
        line-height: 30px;
        border-radius: 5px;
        margin-top: 20px;
        color: #fff;
        cursor: pointer;
    }
    .redio{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
    }
    .oper{
        display: flex;
        justify-content: flex-start;
        margin-top: 30px;
        span{
            display: block;
            width: 80px;
            height: 30px;
            border-radius: 4px;
            background-color: #eee;
            text-align: center;
            margin-right: 10px;
            line-height: 30px;
            &:last-child{
                background-color: red;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
</style>
