<template>
    <div class="reportItem">
        <div class="channel ">
            <el-select v-model="belong_team" placeholder="请选择主客队" style="width:80px">
                <el-option
                v-for="(item, index) in belongOption"
                :key="'belong'+index"
                :label="item.text"
                :value="item.id">
                </el-option>
            </el-select>
            <el-input v-model="tag" label="文本" name="tag" placeholder="情报标签"  style="width:100px;margin-left: 10px;"/>
            <el-input v-model="title" label="文本" name="title" placeholder="请输入情报标题"  style="width:400px;margin-left: 10px"/>
        </div>
        <div class="channel">
            <el-upload
                class="avatar-uploader"
                :action="uploadurl"
                :data="{
                    type: 'report'
                }"
                name="image"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <template v-if="img">
                    <img :src="img" class="avatar">
                    <span @click="handleRemove" class="del">删除图片</span>
                </template>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </div>
        <div class="channel">
            <el-input
            style="width: 600px"
            type="textarea"
            :rows="4"
            placeholder="请输入情报内容"
            v-model="content">
            </el-input>
        </div>
        <div class="channel redio">
            <div class="label">是否选取：</div>
            <div class="radioBox">
                <el-radio v-model="is_used" :label="1">是</el-radio>
                <el-radio v-model="is_used" :label="0">否</el-radio>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'reportItem',
    props: ['data'],
    data () {
        return {
            id: '',
            belong_team: 1, // 1主队 2客队 0中立
            belongOption: [
                {
                    id: 1,
                    text: '主队'
                },
                {
                    id: 2,
                    text: '客队'
                },
                {
                    id: 0,
                    text: '中立'
                }
            ],
            title: '',
            uploadurl: `${process.env.VUE_APP_BASEURL}api/ext/common/upload/image`,
            img: '',
            path: '',
            tag: '',
            content: '',
            is_used: 1
        }
    },
    watch: {
        data: {
            handler () {
                if (this.data.title && this.data.content) {
                    this.id = this.data.id;
                    this.belong_team = this.data.belong_team;
                    this.title = this.data.title;
                    this.img = this.data.img_url;
                    this.path = this.data.path;
                    this.tag = this.data.tag;
                    this.content = this.data.content;
                    this.is_used = this.data.is_used;
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        handleRemove (e) {
            e.stopPropagation();
            this.path = "";
            this.img = "";
        },
        handleAvatarSuccess (response) {
            if (response.code === 0) {
                this.$message.success('上传成功');
                this.img = response.data.url;
                this.path = response.data.path;
            }
        },
        beforeAvatarUpload () {}
    }
}
</script>

<style lang="less" scoped>
.reportItem{
    border: 1px solid #eee;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
    .channel{
        margin-top: 20px;
        /deep/.avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            background-color: #eeeeee !important;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
        }
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
        .del{
            cursor: pointer;
        }
    }
    .redio{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
</style>
